.hero-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  padding-top: 10rem; /* Increased padding to account for navbar */
  min-height: 80vh;
  background-color: #f8f9fa;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

@media (min-width: 992px) {
  .hero-container {
    flex-direction: row;
    padding: 4rem;
  }
}

.hero-content {
  max-width: 550px;
  margin-bottom: 3rem;
}

@media (min-width: 992px) {
  .hero-content {
    width: 45%;
    margin-bottom: 0;
    margin-right: 2rem;
  }
}

.hero-title {
  font-size: 2.5rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
  color: #333;
  line-height: 1.2;
}

.hero-description {
  font-size: 1.125rem;
  color: #666;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.waitlist-form {
  width: 100%;
  margin-top: 1rem;
}

.input-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.75rem;
}

@media (min-width: 576px) {
  .input-group {
    flex-direction: row;
    align-items: center;
  }
}

.email-input {
  flex: 1;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 50px;
  outline: none;
  transition: border-color 0.3s ease;
  font-family: inherit;
}

.email-input:focus {
  border-color: #35605A;
}

.hero-button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  outline: none;
  white-space: nowrap;
}

.hero-button.primary {
  background-color: #35605A;
  color: white;
  min-width: 150px;
  text-align: center;
}

.hero-button.primary:hover {
  background-color: #284842;
}

/* App preview styling */
.app-preview {
  width: 100%;
  max-width: 600px;
  background-color: #f2f7f6;
  border-radius: 20px;
  padding: 2rem;
  box-shadow: 0 10px 30px rgba(53, 96, 90, 0.1);
}

@media (min-width: 992px) {
  .app-preview {
    width: 55%;
  }
}

.app-window {
  background-color: #35605A;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.window-controls {
  display: flex;
  gap: 0.5rem;
}

.window-button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.window-button.red {
  background-color: #ff5f57;
}

.window-button.yellow {
  background-color: #ffbd2e;
}

.window-button.green {
  background-color: #28c940;
}

.app-title {
  margin-left: 1rem;
  font-size: 0.875rem;
  color: #666;
}

.app-content {
  padding: 1rem;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-screenshot {
  max-width: 100%;
  height: auto;
  display: block;
}

.app-placeholder {
  width: 100%;
  height: 400px;
  background-color: #f2f7f6;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #35605A;
  text-align: center;
  border: 2px dashed #35605A;
  border-radius: 8px;
}