.App {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  /* height: 100vh; */
  position: relative;
  overflow: auto;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #333;
  line-height: 1.5;
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

.left-side {
  width: 100%;
}

.right-side {
  width: 100%;
}

/* Add these new styles for scrolling */
.hero-section {
  padding-top: 80px; /* Account for navbar height */
}

#features, #how-it-works, #contact {
  scroll-margin-top: 100px; /* Ensures section titles are visible when scrolled to */
}

/* Optional utility classes */
.text-primary {
  color: #35605A;
}

.bg-primary {
  background-color: #35605A;
}

.text-center {
  text-align: center;
}