.how-it-works-container {
    padding: 4rem 2rem;
    background-color: #f2f7f6;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .how-it-works-title {
    text-align: center;
    font-size: 2.25rem;
    font-weight: 700;
    margin-bottom: 3rem;
    color: #333;
  }
  
  .steps-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  @media (min-width: 768px) {
    .steps-container {
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
    }
  }
  
  .step-card {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1.5rem;
  }
  
  .step-number {
    width: 60px;
    height: 60px;
    background-color: #35605A;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }
  
  .step-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .step-description {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
    max-width: 300px;
  }