/* Page Container */
.reset-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
}

/* Reset Box */
.reset-box {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Titles */
.reset-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.reset-subtitle {
  font-size: 18px;
  margin-top: 10px;
  color: #555;
}

/* Input Groups */
.input-group {
  margin-top: 15px;
  text-align: left;
}

.input-group label {
  font-size: 14px;
  color: #444;
  font-weight: 500;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.input-container input {
  width: 100%;
  padding: 8px;
  padding-right: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.input-error {
  border-color: red !important;
}

/* Eye Icon */
.eye-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  font-size: 16px;
}

/* Error Text */
.error-text {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

/* Alerts */
.alert {
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 15px;
}

.success {
  background-color: #d4edda;
  color: #155724;
}

.error {
  background-color: #f8d7da;
  color: #721c24;
}

/* Reset Button */
.reset-button {
  width: 100%;
  margin-top: 15px;
  padding: 10px;
  background-color: #4e7678;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.reset-button:hover {
  background-color: #3b5f61;
}
