/* ContactForm.css */
.contact-section {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  padding: 4rem 0;
  background-color: #f2f7f6;
}

.contact-container {
  display: flex;
  justify-content: center;
  padding: 0 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.contact-form-container {
  width: 100%;
  max-width: 600px;
  padding: 3rem;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  border: none;
}

.contact-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 1rem;
}

.contact-description {
  font-size: 1.125rem;
  color: #666;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;
}

.form-group {
  width: 100%;
}

.form-input {
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1.125rem;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  transition: border-color 0.3s ease;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  margin-bottom: 1rem;
  height: 60px;
  box-sizing: border-box;
}

.form-input:focus {
  border-color: #35605A;
  box-shadow: 0 0 0 3px rgba(53, 96, 90, 0.1);
  background-color: white;
}

.form-input::placeholder {
  color: #9ca3af;
}

.submit-button {
  width: 100%;
  padding: 1rem 1.25rem;
  background-color: #35605A;
  color: white;
  font-weight: 600;
  font-size: 1.125rem;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.submit-button:hover {
  background-color: #284842;
  transform: translateY(-2px);
}

.submit-button:active {
  transform: translateY(0);
}

.message-alert {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 8px;
  font-weight: 500;
  animation: fadeIn 0.3s ease-in-out;
}

.message-alert.success {
  background-color: rgba(72, 187, 120, 0.1);
  color: #2f855a;
  border: 1px solid rgba(72, 187, 120, 0.2);
}

.message-alert.error {
  background-color: rgba(245, 101, 101, 0.1);
  color: #e53e3e;
  border: 1px solid rgba(245, 101, 101, 0.2);
}

.contact-details {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #e5e7eb;
}

.contact-info {
  font-size: 1.125rem;
  color: #4b5563;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media Queries */
@media (max-width: 768px) {
  .contact-section {
    padding: 3rem 1rem;
  }
  
  .contact-form-container {
    padding: 2rem;
  }
  
  .contact-title {
    font-size: 1.75rem;
  }
  
  .contact-description {
    font-size: 1rem;
  }
}