.features-container {
    padding: 4rem 2rem;
    background-color: #f8f9fa;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .features-title {
    text-align: center;
    font-size: 2.25rem;
    font-weight: 700;
    margin-bottom: 3rem;
    color: #333;
  }
  
  .features-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  @media (min-width: 640px) {
    .features-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 992px) {
    .features-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  .feature-card {
    background-color: white;
    border-radius: 8px;
    padding: 2rem;
    border: 1px solid #eee;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .feature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 15px rgba(53, 96, 90, 0.1);
  }
  
  .feature-icon-container {
    width: 60px;
    height: 60px;
    background-color: rgba(53, 96, 90, 0.1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
  }
  
  .feature-icon {
    font-size: 1.50rem;
  }
  
  .feature-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #35605A;
  }
  
  .feature-description {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
  }