.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem 0;
  background-color: #35605A;
  transition: all 0.3s ease;
  z-index: 1000;
}

.navbar.scrolled {
  padding: 0.75rem 0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 2rem;

}

.navbar-logo {
  display: flex;
  align-items: center;
  cursor: pointer;

}

.navbar-links {
  display: flex;
  gap: 2rem;
}

.nav-link {
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
}

.nav-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  transition: width 0.3s ease;
}

.nav-link:hover::after {
  width: 100%;
}

/* Mobile styles */
@media (max-width: 768px) {
  .navbar-container {
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem 1rem;
  }
  
  .navbar-links {
    width: 100%;
    justify-content: space-around;
    gap: 0.5rem;
  }
  
  .nav-link {
    font-size: 0.875rem;
    padding: 0.25rem;
  }
}